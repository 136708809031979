import { Col, Container, Display, GenericUtil, Justify, Margins, Row } from "@isbj-redesign/frontend-components"
import { ErrorBoundary, Skeleton, SkeletonHeader, ThemeDescriptor, ThemeProvider } from "@isbj-redesign/shared-components"
import React, { ReactNode, useEffect } from "react"
import AuthenticatedApiProvider from "../providers/api"
import { cutParam } from "../utils/utils"
import { NavLinkProps } from "@isbj-redesign/shared-components/dist/esm/Skeleton/links/NavIconAnchor"
import { getLogoutUrl } from "../providers/auth"

export interface Props {
    title?: string
    children: ReactNode
    protectedPage?: boolean
    marginTop?: GenericUtil<Margins>
    actions?: ReactNode
}

const themes: Array<ThemeDescriptor> = [{ label: "Portal", id: "portal" }, { label: "Hoher Kontrast", id: "contrast" }]

const fachkontext = "Tagesbetreuung"
const fachverfahren = "Anfrageliste"

let dashboardUrl: undefined | string = undefined
if (window.isbj && window.isbj.env) {
    dashboardUrl = window.isbj.env.dashboardUrl
}

function wrapInWhen(Comp: (props: any) => JSX.Element, when: boolean, children: ReactNode): any {
    if (when) {
        return <Comp>{children}</Comp>
    } else {
        return children
    }
}

function createTitle(title: string, actions: ReactNode): string | ReactNode {
    if (actions) {
        return (
            <Row>
                <Col xs={12} lg={6} order={{ xs: 1 }}>
                    <SkeletonHeader className="" title={title} />
                </Col>
                <Col xs={12} lg={6} order={{ xs: 2, lg: 3 }} display={Display.Flex} justifyContent={Justify.End}>
                    {actions}
                </Col>
            </Row>
        )
    }
    return title
}

const home: NavLinkProps = {
    external: true,
    href: dashboardUrl || "",
    title: "Portalbenutzer Dashboard",
}

export default function Layout({ title, children, protectedPage = false, marginTop = 3, actions }: Props) {
    useEffect(() => {
        document.title = `${fachkontext}${title ? " • " + title : fachverfahren}`
    }, [title])

    const origin = decodeURIComponent(cutParam("origin")) || "/liste"
    const jwt = cutParam("jwt", false)
    const user: NavLinkProps = {
        title: jwt ? "Ausloggen" : "Einloggen",
        href: getLogoutUrl(),
        external: true,
    }

    return (
        <ThemeProvider themes={themes}>
            <Skeleton
                fachkontext={fachkontext}
                title={createTitle(title || fachverfahren, actions)}
                links={{ home, cancel: { href: origin, external: true }, user }}
            >
                <ErrorBoundary>
                    <Container fluid marginTop={marginTop}>
                        {wrapInWhen(AuthenticatedApiProvider, protectedPage, children)}
                    </Container>
                </ErrorBoundary>
            </Skeleton>
        </ThemeProvider>
    )
}
