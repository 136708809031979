export const AnfrageBearbeitungFormFields = {
    Betreuungsumfang: {
        Betreuungsbeginn: "betreuungsumfang.betreuungsbeginn",
        Betreuungszeit: "betreuungsumfang.betreuungszeit",
        Einrichtung: "einrichtung",
    },
    Betreuungsperson: {
        Vorname: "betreuungsperson.vorname",
        Nachname: "betreuungsperson.nachname",
        Telefon: "betreuungsperson.telefon",
        Adresse: {
            Strasse: "betreuungsperson.adresse.strasse",
            Hausnummer: "betreuungsperson.adresse.hausnummer",
            Plz: "betreuungsperson.adresse.plz",
            Ort: "betreuungsperson.adresse.ort",
        },
        Geschlecht: "betreuungsperson.geschlecht",
        Email: "betreuungsperson.email",
    },
    Kind: {
        Vorname: "kind.vorname",
        Nachname: "kind.nachname",
        Geburtsdatum: "kind.geburtsdatum",
        Geschlecht: "kind.geschlecht",
        Geboren: "kind.geboren",
        Adresse: {
            GleicheAnschrift: "kind.gleicheanschrift",
            Strasse: "kind.adresse.strasse",
            Hausnummer: "kind.adresse.hausnummer",
            Plz: "kind.adresse.plz",
            Ort: "kind.adresse.ort",
        },
    },
}
