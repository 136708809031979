import React, { ReactNode } from "react"
import { NotificationData } from "@isbj-redesign/shared-components"

function createNotification(title: string, type: string, message: ReactNode) {
    return {
        title,
        type,
        message,
    } as any
}

export function createSuccessNotification(message: NotificationData["message"]): NotificationData {
    return createNotification("Bestätigung", "success", message)
}

export function createErrorNotification(message: NotificationData["message"]): NotificationData {
    return createNotification("Fehler", "error", message)
}

function createIsoDateTimeString() {
    const nowISO = new Date().toISOString()
    return nowISO
        .slice(0, nowISO.length - 5)
        .split("T")
        .join(" ")
}

export function genericError(error?: any) {
    return createErrorNotification(
        <p className="mb-0">
            Fehler beim Ausführen der Anfrage. ({createIsoDateTimeString()}).
            <br />
            Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.
            {error ? (
                <>
                    <br />
                    {JSON.stringify(error, null, 2)}
                </>
            ) : null}
        </p>
    )
}
