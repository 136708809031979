import { FieldDatePicker, FieldSelect } from "@isbj-redesign/final-form-components"
import { Col, Row } from "@isbj-redesign/frontend-components"
import React from "react"
import EinrichtungInput from "../../../components/EinrichtungInput"
import { AnfrageBearbeitungFormFields } from "../AnfrageBearbeitungFormFields"
import { Betreuungszeit } from "../../../core/model/Betreuungszeit"
const { Betreuungsumfang } = AnfrageBearbeitungFormFields

export default function BetreuungsBearbeitung({ edit }: { edit?: boolean }) {
    return (
        <>
            <h4 className="mt-3">Informationen zur Betreuung</h4>
            <hr />
            <Row>
                {edit ? null : (
                    <Col xs={12} md={6} lg={12} xl={4}>
                        <EinrichtungInput name={Betreuungsumfang.Einrichtung} required />
                    </Col>
                )}
                <Col xs={12} md={6} lg={12} xl={edit ? 6 : 4}>
                    <FieldDatePicker name={Betreuungsumfang.Betreuungsbeginn} label="Betreuungsbeginn" required />
                </Col>
                <Col xs={12} md={6} lg={12} xl={edit ? 6 : 4}>
                    <FieldSelect
                        name={Betreuungsumfang.Betreuungszeit}
                        label="Betreuungszeit"
                        options={[
                            { label: "(bitte auswählen)", value: "" },
                            { label: "halbtags (4 bis 5 Stunden täglich)", value: Betreuungszeit.Halbtags },
                            { label: "Teilzeit (5 bis 7 Stunden täglich)", value: Betreuungszeit.Teilzeit },
                            { label: "ganztags (7 bis 9 Stunden täglich)", value: Betreuungszeit.Ganztags },
                            { label: "erweitert (über 9 Stunden täglich)", value: Betreuungszeit.Erweitert },
                        ]}
                    />
                </Col>
            </Row>
        </>
    )
}
