import { FieldInput } from "@isbj-redesign/final-form-components"
import { Col, Row } from "@isbj-redesign/frontend-components"
import React from "react"
import { FormRenderProps } from "react-final-form"
import { AnfrageBearbeitungFormFields as Fields } from "../AnfrageBearbeitungFormFields"
import FieldNachnameInput from "../../../components/FieldNachnameInput"
import FieldVornameInput from "../../../components/FieldVornameInput"
import FieldPlzInput from "../../../components/FieldPlzInput"

export interface Props {
    form: FormRenderProps
}

const { Betreuungsperson } = Fields

export default function BetreuungspersonBearbeitung() {
    return (
        <>
            <h4 className="mt-3">Angaben zur Betreuungsperson</h4>
            <hr />
            <Row>
                <Col xs={12} sm={6}>
                    <FieldNachnameInput name={Betreuungsperson.Nachname} required />
                </Col>
                <Col xs={12} sm={6}>
                    <FieldVornameInput name={Betreuungsperson.Vorname} required />
                </Col>
                <Col xs={12} sm={8} lg={9} xl={9}>
                    <FieldInput name={Betreuungsperson.Adresse.Strasse} label="Straße" required maxLength={255} />
                </Col>
                <Col xs={12} sm={4} lg={3} xl={3}>
                    <FieldInput name={Betreuungsperson.Adresse.Hausnummer} label="Hausnummer" required maxLength={10} />
                </Col>
                <Col xs={12} sm={4} lg={3} xl={3}>
                    <FieldPlzInput name={Betreuungsperson.Adresse.Plz} required />
                </Col>
                <Col xs={12} sm={8} lg={9} xl={9}>
                    <FieldInput name={Betreuungsperson.Adresse.Ort} label="Ort" required maxLength={255} />
                </Col>
                <Col xs={12} sm={6}>
                    <FieldInput name={Betreuungsperson.Telefon} label="Telefon" maxLength={255} restrictions={/[\d\-\/\s\+]/g} />
                </Col>
                <Col xs={12} sm={6}>
                    <FieldInput name={Betreuungsperson.Email} label="E-Mail" maxLength={255} />
                </Col>
            </Row>
        </>
    )
}
