import React from "react"
import { Textarea } from "@isbj-redesign/frontend-components"

export interface Props {
    kontakt?: string | null
}

export default function KontaktInformationen({ kontakt }: Props) {
    if (kontakt === null || kontakt === undefined || (typeof kontakt === "string" && kontakt.length === 0)) {
        return null
    }

    return <Textarea label="Kontakt" value={kontakt} readOnly />
}
