import React from "react"
import { RBSInformationen } from "../../../core/model/RBSInformationen"

export interface Props {
    adresse: RBSInformationen
}

export default function RBSInformationenGrid({ adresse }: Props) {
    return (
        <>
            <div className="d-flex w-100 justify-content-between">
                <dt>RBS-Status</dt>
                <dd className="mb-0">{adresse.rbsStatus}</dd>
            </div>
            <hr className="my-2" />
            <div className="d-flex w-100 justify-content-between">
                <dt>Bezirk</dt>
                <dd className="mb-0">{adresse.bezirk}</dd>
            </div>
            <hr className="my-2" />
            <div className="d-flex w-100 justify-content-between">
                <dt>Ortsteil</dt>
                <dd className="mb-0">{adresse.ortsteil}</dd>
            </div>
            <hr className="my-2" />
            <div className="d-flex w-100 justify-content-between">
                <dt>LOR</dt>
                <dd className="mb-0">{adresse.planungsraum}</dd>
            </div>
        </>
    )
}
