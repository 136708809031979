import React from "react"
import { FieldRadiobuttons } from "@isbj-redesign/final-form-components"
import { Option } from "@isbj-redesign/frontend-components"
import { Geschlecht } from "../core/model/Geschlecht"

export interface Props {
    name: string
    required?: boolean
    disabled?: boolean
}

export const GeschlechtOptions: Array<Option> = [
    {
        value: Geschlecht.Maennlich,
        label: "Männlich",
    },
    {
        value: Geschlecht.Weiblich,
        label: "Weiblich",
    },
    {
        value: Geschlecht.Divers,
        label: "Divers",
    },
    {
        value: Geschlecht.OhneAngabe,
        label: "Keine Angabe gem. § 45b Personenstandsgesetz",
    },
]

export default function GeschlechtAuswahl({ disabled, ...rest }: Props) {
    return <FieldRadiobuttons {...rest} label="Geschlecht" options={GeschlechtOptions} inline disabled={disabled} />
}
