import { ParameterUtils } from "@nortal/commons-frontend"
import { Row, Col, Input, DateUtils } from "@isbj-redesign/frontend-components"
import React from "react"
import { AnfrageBetreuungsumfang } from "../../../../core/model/Anfrage"
import { Betreuungszeit } from "../../../../core/model/Betreuungszeit"

export interface Props {
    betreuungsumfang: AnfrageBetreuungsumfang
}

const Betreuungszeiten = {
    "": "",
    [Betreuungszeit.Unbekannt]: "",
    [Betreuungszeit.Halbtags]: "halbtags (4 bis 5 Stunden täglich)",
    [Betreuungszeit.Teilzeit]: "Teilzeit (5 bis 7 Stunden täglich)",
    [Betreuungszeit.Ganztags]: "ganztags (7 bis 9 Stunden täglich)",
    [Betreuungszeit.Erweitert]: "erweitert (über 9 Stunden täglich)",
}

export default function BetreuungsumfangAnzeigen({ betreuungsumfang }: Props) {
    return (
        <>
            <h4 className="mt-3">Informationen zur Betreuung</h4>
            <hr />
            <Row>
                <Col xs={12} md={6} lg={12} xl={4}>
                    <Input
                        id="betreuungsbeginn"
                        value={
                            betreuungsumfang.betreuungsbeginn ? DateUtils.convertDateToString(ParameterUtils.parseDate(betreuungsumfang.betreuungsbeginn)!) : ""
                        }
                        label="Betreuungsbeginn"
                        readOnly
                        plaintext
                    />
                </Col>
                <Col xs={12} md={6} lg={12} xl={4}>
                    <Input value={Betreuungszeiten[betreuungsumfang.betreuungszeit]} label="Betreuungszeit" readOnly plaintext />
                </Col>
            </Row>
        </>
    )
}
