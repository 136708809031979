import { useGet } from "@isbj-redesign/frontend-api"
import { LoadingIndicator } from "@isbj-redesign/shared-components"
import React, { ReactNode } from "react"
import { VormerkungAnfrageRestModel } from "../../../../rest/vormerkung/model/VormerkungAnfrageRestModel"
import { Anfrage } from "../../../../core/model/Anfrage"
import { vormerkungAnfrageRestConverter } from "../../../../rest/vormerkung/converter/VormerkungAnfrageRestConverter"
import ApiError from "../../../../components/ApiError"
import StatusCode from "../../../../core/StatusCode"
import { Redirect } from "react-router"

export interface Props {
    id: string
    children: (props: Anfrage) => ReactNode
}

export function VormerkungAnfrageLoader({ children, id }: Props) {
    const { data, error, loading } = useGet<VormerkungAnfrageRestModel>({
        path: `/api/v1/vormerkungen/${id}/anfrage`,
    })

    if (error) {
        if ((error as any).statusCode === StatusCode.NotFound) {
            return <Redirect to={"/vormerkungen/not-found"} />
        } else {
            return <ApiError error={error} />
        }
    }

    if (loading) {
        return <LoadingIndicator />
    }

    return <>{children(vormerkungAnfrageRestConverter(data!))}</>
}
