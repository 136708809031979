export const requiredMessage = "Bitte füllen Sie dieses Feld aus."
export const mailRequiredIfNoNamesGivenMessage = "Bitte füllen Sie dieses Feld aus, falls nicht der vollständige Name angegeben wird."
export const nameIncompleteMessage = `Bitte füllen Sie dieses Feld aus, falls keine E-Mail-Adresse angegeben wird.`
export const adressIsIncompleteMessage = `Bitte füllen Sie dieses Feld aus, um die Adresse zu vervollständigen.`

export const ValidationMessage = {
    ExactLengthFive: "Eingabe muss fünfstellig sein.",
    Required: requiredMessage,
    InvalidDate: "Bitte geben Sie ein gültiges Datum ein.",
    InvalidEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
    /** Das Datum darf nicht in der Zukunft liegen.  */
    DateNotInFuture: "Das Datum darf nicht in der Zukunft liegen.",
}
