import { MutationSpec, Mutator, useMutation } from "@isbj-redesign/frontend-api"
import { LoadingIndicator } from "@isbj-redesign/shared-components"
import React, { ReactNode } from "react"
import { Redirect } from "react-router"
import { TrackingResponse } from "../../core/model/TrackingResponse"
import { AnfrageRestModel } from "../../rest/anfrage/AnfrageRestModel"
import { cutParam } from "../../utils/utils"

export interface FormSubmitterProps {
    children(update: Mutator<AnfrageRestModel, TrackingResponse>): ReactNode
    mutation: MutationSpec<AnfrageRestModel>
}

export function AnfrageFormSubmitter({ children, mutation }: FormSubmitterProps) {
    const [update, { loading, error, data }] = useMutation<AnfrageRestModel, TrackingResponse>(mutation)

    if (loading) {
        return <LoadingIndicator />
    }

    if (data || error) {
        const origin = decodeURIComponent(cutParam("origin"))
        const listeZurueck = origin ? origin.replace(location.origin, "") : "/liste"
        return <Redirect to={listeZurueck} />
    }

    return children(update) as JSX.Element
}
