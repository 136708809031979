import { Form } from "@isbj-redesign/final-form-components"
import { MutationSpec } from "@isbj-redesign/frontend-api"
import { Col, Row, Tab, Tabs } from "@isbj-redesign/frontend-components"
import FaFileSignatureLight from "@isbj-redesign/icons/FaFileSignatureLight"
import { useNotifications } from "@isbj-redesign/shared-components"
import React, { useState } from "react"
import { Notifications } from "../../components/CustomNotification"
import Layout from "../../components/Layout"
import AnfrageFormFooter from "../../forms/anfrage/AnfrageFormFooter"
import { Anfrage, AnfrageInformationen } from "../../core/model/Anfrage"
import { AnfrageFormSubmitter } from "../../forms/anfrage/AnfrageFormSubmitter"
import AuthenticatedApiProvider from "../../providers/api"
import { AnfrageRestModel } from "../../rest/anfrage/AnfrageRestModel"
import { createSuccessNotification, genericError } from "../../utils/notifications/NotificationUtils"
import { cutParam, reverseNullifyEmptyString, validate } from "../../utils/utils"
import BetreuungsBearbeitung from "../bearbeitung/forms/BetreuungsBearbeitung"
import BetreuungspersonBearbeitung from "../bearbeitung/forms/BetreuungspersonBearbeitung"
import KindBearbeitung from "../bearbeitung/forms/KindBearbeitung"
import { handleAnfragelisteAktionError } from "../liste/actions/ActionErrors"
import erstellenValidierungsSchema from "./validierung"

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
const initialValues: Omit<Anfrage, keyof AnfrageInformationen> & { einrichtung: string } = {
    betreuungsperson: {
        adresse: {
            hausnummer: "",
            ort: "",
            plz: "",
            strasse: "",
        },
        kontakt: null,
        email: "",
        nachname: "",
        vorname: "",
        telefon: "",
    },
    betreuungsumfang: {
        betreuungsbeginn: "",
        betreuungszeit: "" as any,
    },
    einrichtung: "",
    kind: {
        adresse: {
            bezirk: "",
            hausnummer: "",
            ort: "",
            ortsteil: "",
            planungsraum: "",
            plz: "",
            rbsStatus: "",
            strasse: "",
        },
        einrichtungsEntfernung: "",
        geboren: true,
        geburtsdatum: "",
        geschlecht: "",
        gleicheanschrift: true,
        nachname: "",
        vorname: "",
        mehrling: true,
    },
}

function createAnfrageSuccessNotification(trackingnummer: string) {
    return createSuccessNotification(
        <>
            <p>Die Betreuungsanfrage wurde erstellt.</p>
            <dl className="mb-0 d-flex">
                <dt>Trackingnummer:</dt>
                <dd className="mb-0 ml-1">{trackingnummer}</dd>
            </dl>
        </>
    )
}

const createAnfrageMutation: MutationSpec<AnfrageRestModel> = {
    path() {
        return `/api/v1/anfragen`
    },
    method: "POST",
}

export default function AnfrageErstellen() {
    const [tab, setTab] = useState(0)
    const origin = decodeURIComponent(cutParam("origin"))
    const listeZurueck = origin ? origin.replace(location.origin, "") : "/liste"
    const { addNotification } = useNotifications()

    return (
        <Layout title="Anfrage erstellen" protectedPage marginTop={0}>
            <AuthenticatedApiProvider>
                <Tabs activeIndex={tab} onClick={(index) => setTab(index)} className="bg-white row pt-3 mb-3 px-3">
                    <Tab
                        title={
                            <h3 className="m-0">
                                <FaFileSignatureLight className="mr-2" style={{ fontSize: "1rem" }} />
                                Angaben zur Anfrage
                            </h3>
                        }
                    >
                        <AnfrageFormSubmitter mutation={createAnfrageMutation}>
                            {(update) => (
                                <Form
                                    initialValues={{
                                        ...initialValues,
                                    }}
                                    subscription={{ values: true }}
                                    onSubmit={(values) => {
                                        update({ variables: reverseNullifyEmptyString(values) }).then(({ success, data, error }) => {
                                            if (success && data) {
                                                addNotification(createAnfrageSuccessNotification(data.trackingnummer))
                                            } else if (error) {
                                                handleAnfragelisteAktionError(error, addNotification)
                                            } else {
                                                genericError({ values, success, data, error })
                                            }
                                        })
                                    }}
                                    validate={(values) => validate(values, erstellenValidierungsSchema)}
                                >
                                    {(form: { values: Anfrage }) => {
                                        return (
                                            <>
                                                <Row>
                                                    <Col xs={12}>
                                                        <KindBearbeitung
                                                            geboren={form.values.kind.geboren}
                                                            gleicheanschrift={form.values.kind.gleicheanschrift}
                                                            erstellen
                                                        />
                                                        <BetreuungsBearbeitung />
                                                        <BetreuungspersonBearbeitung />
                                                    </Col>
                                                </Row>
                                                <AnfrageFormFooter abbrechenHref={listeZurueck} />
                                            </>
                                        )
                                    }}
                                </Form>
                            )}
                        </AnfrageFormSubmitter>
                    </Tab>
                </Tabs>
            </AuthenticatedApiProvider>
            <Notifications />
        </Layout>
    )
}
