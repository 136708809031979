import { DateUtils } from "@isbj-redesign/frontend-components"
import { ValidationError } from "yup"

export async function validateValidDateField(field: string, obj: any) {
    const value: string = obj[field]
    return isValidDate(value) || new ValidationError("Bitte geben Sie ein gültiges Datum ein.", "", field)
}

export function isValidDate(date: string) {
    if (date === undefined || date.length === 0) {
        return true
    }

    const split = date.split("-")

    if (split.length === 3) {
        if ((DateUtils as any).isValidDateStringArray(split)) {
            return true
        }
    }

    return false
}
