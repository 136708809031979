import { Alert, Color, Text } from "@isbj-redesign/frontend-components"
import React from "react"
import Layout from "../../components/Layout"
import { getLogoutUrl } from "../../providers/auth"
import { getOrigin } from "../../services/getOrigin"

export default function NotFound() {
    const fallbackUrl = getOrigin() || getLogoutUrl()

    return (
        <Layout title="Anfrageliste - Vormerkung nicht vorhanden" protectedPage>
            <Alert color={Color.Info}>
                <h3>Vormerkung nicht vorhanden</h3>
                <hr />
                <p className="mb-0">
                    Es konnte keine Vormerkung zu ihrer Anfrage gefunden werden. Klicken sie{" "}
                    <a href={fallbackUrl} className="">
                        <Text bold>
                            <u>hier</u>
                        </Text>
                    </a>{" "}
                    um zurück zum Trägerportal zu gelangen.
                </p>
            </Alert>
        </Layout>
    )
}
