import * as React from "react"
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom"
import AuthenticatedApiProvider from "../../providers/api"
import VormerkungenAnzeigen from "./anzeigen"
import NotFound from "./NotFound"

const tabs = [{ path: "/anzeigen/:id", component: VormerkungenAnzeigen }]

function Vormerkungen({ match }: RouteComponentProps) {
    return (
        <AuthenticatedApiProvider>
            <Switch>
                {tabs.map(tab => (
                    <Route key={tab.path} path={`${match.path}${tab.path}`} component={tab.component} />
                ))}
                <Route component={NotFound} />
            </Switch>
        </AuthenticatedApiProvider>
    )
}

export default withRouter(Vormerkungen)
