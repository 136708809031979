import { object, string, ValidationError } from "yup"
import { AnfrageBearbeitungFormFields } from "../AnfrageBearbeitungFormFields"
import { requiredMessage } from "../../../core/validation/Messages"
import { adresseValidierungShape } from "./AdresseValidierung"

const { Betreuungsperson } = AnfrageBearbeitungFormFields
const key = "betreuungsperson."
// tslint:disable-next-line:max-line-length
const emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const emailValidationText = "Bitte geben Sie eine gültige E-Mail-Adresse ein."

export const betreuungspersonValidierungsShape = object()
    .shape({
        [Betreuungsperson.Vorname.replace(key, "")]: string().required(requiredMessage), // Wenn dass Betreuungsperson bereits geboren ist
        [Betreuungsperson.Nachname.replace(key, "")]: string().required(requiredMessage),
        adresse: adresseValidierungShape,
    })
    .test(Betreuungsperson.Email.replace(key, ""), "", async function(obj) {
        if (!obj) {
            return true
        }

        const { email } = obj

        if (typeof email !== "string" || email.length === 0) {
            return true
        }

        if (emailregex.test(email)) {
            return true
        }

        return new ValidationError(emailValidationText, "", Betreuungsperson.Email)
    })
