import { DateUtils } from "@isbj-redesign/frontend-components"
import { object, ValidationError } from "yup"
import { AnfrageBearbeitungFormFields } from "../AnfrageBearbeitungFormFields"
import { betreuungspersonValidierungsShape } from "./BetreuungspersonValidierung"
import { kindValidierungShape } from "./KindValidierung"
import { requiredMessage } from "../../../core/validation/Messages"
import { isEmptyString } from "../../../core/validation/StringValidator"
import { isValidDate } from "../../../core/validation/DateValidator"

const { Betreuungsumfang } = AnfrageBearbeitungFormFields

// Bitte nicht nochmal so schreiben
export const bearbeitungsValidierungsSchema = object().shape({
    kind: kindValidierungShape,
    betreuungsperson: betreuungspersonValidierungsShape,
    betreuungsumfang: object().test("betreuungsbeginn", "", async function(obj) {
        const betreuungsbeginnString: string = obj.betreuungsbeginn
        const geburtsdatumString: string = this.parent.kind.geburtsdatum

        if (!isValidDate(betreuungsbeginnString)) {
            return new ValidationError("Bitte geben Sie ein gültiges Datum ein.", "", Betreuungsumfang.Betreuungsbeginn)
        }

        if (isEmptyString(betreuungsbeginnString)) {
            return new ValidationError(requiredMessage, "", Betreuungsumfang.Betreuungsbeginn)
        }

        const splitBetreuungsbeginn = betreuungsbeginnString.split("-")
        if (splitBetreuungsbeginn.length === 3) {
            if ((DateUtils as any).isValidDateStringArray(splitBetreuungsbeginn)) {
                const betreuungsbeginnDatum = new Date(
                    parseInt(splitBetreuungsbeginn[0]),
                    parseInt(splitBetreuungsbeginn[1]) - 1,
                    parseInt(splitBetreuungsbeginn[2])
                )

                if (typeof geburtsdatumString === "string" && geburtsdatumString.length > 0) {
                    if (typeof geburtsdatumString === "string" && geburtsdatumString.length > 0) {
                        const splitGeburtstag = geburtsdatumString.split("-")

                        if (splitGeburtstag.length === 3) {
                            if ((DateUtils as any).isValidDateStringArray(splitGeburtstag)) {
                                // nicht vor dem Geburtsdatum
                                const geburtsdatum = new Date(parseInt(splitGeburtstag[0]), parseInt(splitGeburtstag[1]) - 1, parseInt(splitGeburtstag[2]))
                                if (geburtsdatum.getTime() > betreuungsbeginnDatum.getTime()) {
                                    return new ValidationError(
                                        "Der Betreuungsbeginn darf nicht vor dem Geburtsdatum liegen.",
                                        "",
                                        Betreuungsumfang.Betreuungsbeginn
                                    )
                                }

                                // nicht nach 10 Jahre nach Geburtsdatum
                                const geburtsdatumInZehnJahren = new Date(
                                    parseInt(splitGeburtstag[0]) + 10,
                                    parseInt(splitGeburtstag[1]) - 1,
                                    parseInt(splitGeburtstag[2])
                                )
                                const zehnJahreInDerZukunft = geburtsdatumInZehnJahren.getTime()
                                if (betreuungsbeginnDatum.getTime() > zehnJahreInDerZukunft) {
                                    return new ValidationError(
                                        "Der Betreuungsbeginn darf nicht mehr als 10 Jahre nach dem Geburtsdatum liegen.",
                                        "",
                                        Betreuungsumfang.Betreuungsbeginn
                                    )
                                }
                            }
                        }
                    }
                }

                // nicht nach Juli in 2 Jahren
                const juliInZweiJahren = new Date(new Date().getFullYear() + 2, 6, 31, 23, 59)
                if (betreuungsbeginnDatum.getTime() > juliInZweiJahren.getTime()) {
                    return new ValidationError(
                        "Der Betreuungsbeginn darf nicht nach dem Monat Juli in zwei Jahren liegen.",
                        "",
                        Betreuungsumfang.Betreuungsbeginn
                    )
                }
            }
        }

        return true
    }),
})
