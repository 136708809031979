import React from "react"
import { render } from "react-dom"
import { BrowserRouter } from "react-router-dom"
import Root from "./pages"

function App() {
    return (
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    )
}

render(<App />, document.getElementById("app"))
