import { LoadingIndicator, NotificationsProvider } from "@isbj-redesign/shared-components"
import React, { lazy, Suspense } from "react"
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom"
import AnfrageErstellen from "./erstellen"
import "../utils/notifications/Notifications.scss"
import Vormerkungen from "./vormerkungen"

const Liste = lazy(() => import("./liste"))
const Verwaltung = lazy(() => import("./verwaltung"))
const Bearbeitung = lazy(() => import("./bearbeitung"))
const AuskunftDSGVO = lazy(() => import("./dsgvo"))

const tabs = [
    { component: Liste, path: "liste" },
    { component: Verwaltung, path: "verwaltung" },
    { component: Bearbeitung, path: "bearbeitung/:id" },
    { component: AnfrageErstellen, path: "erstellen" },
    { component: Vormerkungen, path: "vormerkungen" },
    { component: AuskunftDSGVO, path: "auskunft" },
]

function Root({ match }: RouteComponentProps) {
    return (
        <Suspense fallback={<LoadingIndicator />}>
            <NotificationsProvider>
                <Switch>
                    {tabs.map((tab) => (
                        <Route key={tab.path} path={`${match.path}${tab.path}`} component={tab.component} />
                    ))}
                    <Redirect to={`${match.path}${tabs[0].path}${location.search}`} />
                </Switch>
            </NotificationsProvider>
        </Suspense>
    )
}

export default withRouter(Root)
