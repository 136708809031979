import * as React from "react"
import { User } from "./api"

export interface AuthContext {
    user: User
}

const defaultContext: AuthContext = {
    get user(): User {
        throw new Error("AuthContext only works with AuthProvider!")
    },
}

export const Context = React.createContext(defaultContext)
