import { FieldDatePicker, FieldInput, FieldToggleSwitch } from "@isbj-redesign/final-form-components"
import { Col, Direction, Row } from "@isbj-redesign/frontend-components"
import React from "react"
import FieldNachnameInput from "../../../components/FieldNachnameInput"
import FieldPlzInput from "../../../components/FieldPlzInput"
import FieldVornameInput from "../../../components/FieldVornameInput"
import GeschlechtAuswahl from "../../../components/GeschlechtAuswahl"
import { AnfrageBearbeitungFormFields as Fields } from "../AnfrageBearbeitungFormFields"

export interface Props {
    geboren: boolean
    gleicheanschrift: boolean
    erstellen?: boolean
}

const {
    Kind,
    Betreuungsperson: { Adresse: BetreuungspersonAdresse },
} = Fields
const { Adresse } = Kind

export default function KindBearbeitung({ geboren, gleicheanschrift, erstellen }: Props) {
    return (
        <>
            <h4>Angaben zum Kind</h4>
            <hr />
            <Row>
                <Col xs={12} sm={6}>
                    <FieldNachnameInput name={Kind.Nachname} required />
                </Col>
                <Col xs={12} sm={6}>
                    <FieldVornameInput name={Kind.Vorname} required={geboren} disabled={!geboren && erstellen} />
                </Col>
                <Col xs={12} marginBottom={1}>
                    <FieldToggleSwitch labelDirection={Direction.Right} name={Kind.Geboren} label="Ist das Kind bereits geboren?" />
                </Col>
                <Col xs={12} xl={6}>
                    <FieldDatePicker name={Kind.Geburtsdatum} label="Geburtsdatum" required={geboren} disabled={!geboren && erstellen} />
                </Col>
                <Col xs={12} xl={6}>
                    <GeschlechtAuswahl name={Kind.Geschlecht} required={geboren} disabled={!geboren && erstellen} />
                </Col>
                <Col xs={12} marginBottom={1} marginTop={3}>
                    <FieldToggleSwitch labelDirection={Direction.Right} name={Kind.Adresse.GleicheAnschrift} label="Gleiche Anschrift wie Betreuungsperson?" />
                </Col>
                <Col xs={12} sm={8} lg={9} xl={9}>
                    <FieldInput
                        name={gleicheanschrift ? BetreuungspersonAdresse.Strasse : Adresse.Strasse}
                        label="Straße"
                        required
                        disabled={gleicheanschrift}
                        maxLength={255}
                    />
                </Col>
                <Col xs={12} sm={4} lg={3} xl={3}>
                    <FieldInput
                        name={gleicheanschrift ? BetreuungspersonAdresse.Hausnummer : Adresse.Hausnummer}
                        label="Hausnummer"
                        required
                        disabled={gleicheanschrift}
                        maxLength={10}
                    />
                </Col>
                <Col xs={12} sm={4} lg={3} xl={3}>
                    <FieldPlzInput name={gleicheanschrift ? BetreuungspersonAdresse.Plz : Adresse.Plz} required disabled={gleicheanschrift} />
                </Col>
                <Col xs={12} sm={8} lg={9} xl={9}>
                    <FieldInput
                        name={gleicheanschrift ? BetreuungspersonAdresse.Ort : Adresse.Ort}
                        label="Ort"
                        required
                        disabled={gleicheanschrift}
                        maxLength={255}
                    />
                </Col>
            </Row>
        </>
    )
}
