import React from "react"
import StatusCode from "../core/StatusCode"
import ErrorAlert from "./ErrorAlert"
import { AuthAlert } from "../providers/auth"

export type Props = {
    error: Error
    overrides?: {
        "404": string
    }
}

export default function ApiError({ error }: Props) {
    const { statusCode, statusText, message } = error as any
    const nachricht = message || statusText || "Interner Fehler."
    switch (statusCode) {
        case StatusCode.BadRequest:
            return (
                <ErrorAlert>
                    {StatusCode.BadRequest} - {nachricht}
                </ErrorAlert>
            )
        case StatusCode.Unauthorized:
            return <AuthAlert>Ihre Anmeldung ist abgelaufen</AuthAlert>
        case StatusCode.Forbidden:
            return <ErrorAlert>{StatusCode.Forbidden} - Der authentifizierte Benutzer hat nicht die benötigte Berechtigung für diese Aktion</ErrorAlert>
        case StatusCode.NotFound:
            return <ErrorAlert>{nachricht}</ErrorAlert>
        default:
            throw new Error(nachricht)
    }
}
