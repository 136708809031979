import { CookieUtils, ParameterUtils } from "@nortal/commons-frontend"
import { ObjectSchema } from "yup"
import { setIn } from "final-form"

function getDomain(local: boolean = true): string {
    const host = location.host || location.hostname

    if (local) {
        return host
    }

    const hostArray = host.split(".")

    if (host.length > 1) {
        return hostArray.slice(1).join(".")
    }

    return host
}

export function cutParam(param: string, local = true) {
    const { [param]: parameter, ...rest } = ParameterUtils.parametersToObject(location.search)

    if (parameter) {
        CookieUtils.set(param, parameter, { path: "/", domain: getDomain(local) })
        history.pushState(null, document.title, `${location.pathname}${ParameterUtils.objectToParameter(rest)}`)
    } else {
        const cookieParam = CookieUtils.get(param)
        return typeof cookieParam === "string" ? cookieParam : ""
    }

    return parameter
}

function capitalize(str: string) {
    return `${str[0].toUpperCase()}${str.slice(1)}`
}

export function transformEnumString(str: string | undefined | null) {
    if (typeof str === "string") {
        return capitalize(
            str
                .toLowerCase()
                .replace(/_/g, " ")
                .replace(/oe/g, "ö")
        )
    }
    return ""
}

export function getNumberOfDaysUntil(end: Date, from: Date = new Date()) {
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    let diffInMillis = end.getTime() - from.getTime();
    let diffInDays = diffInMillis / oneDay;
    return Math.trunc(Math.max(diffInDays, 0));
}

export const validate = async (values: object, schema: ObjectSchema<{}>) => {
    const s = schema
    try {
        await s.validate(values, { abortEarly: false, context: { data: values } })
    } catch (e) {
        try {
            return e.inner.reduce((errors: object, error: { path: string; message: string }) => {
                return setIn(errors, error.path, error.message)
            }, {})
        } catch (innerE) {
            console.error(
                "Fehler beim Auswerten der Validierung!\n\n",
                "Fehler der bei der Validierung ausgewertet wurde:",
                e,
                "\n\nFehler der Dabei auftauchte:",
                innerE
            )
        }
    }
}

export function reverseNullifyEmptyString<T extends { [key: string]: any }>(obj: T) {
    return Object.keys(obj).reduce<any>(
        (res, key: keyof T) => {
            const val = obj[key]

            if (typeof val === "string" && val.length === 0) {
                res[key] = null
            } else if (val === null || val === undefined) {
                res[key] = null
            } else if (typeof val === "object") {
                res[key] = reverseNullifyEmptyString(val)
            } else {
                res[key] = val
            }

            return res
        },
        {} as any
    )
}

export function isBlank(value: string | null | undefined) {
    return value === null || value === undefined || value.trim().length === 0
}
