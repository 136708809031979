import React from "react"
import { NotificationsContext, NotificationData } from "@isbj-redesign/shared-components"
import StatusCode from "../../../core/StatusCode"
import { genericError } from "../../../utils/notifications/NotificationUtils"
import { Response } from "@isbj-redesign/frontend-api"

export function handleAnfragelisteAktionError(error: any, addNotification: NotificationsContext["addNotification"]) {
    if (error.statusCode === StatusCode.Conflict) {
        addNotification({
            title: "Daten veraltet",
            message: (
                <p className="mb-0">
                    Die Anfrage, die Sie bearbeiten wollten, ist bereits in einem anderen Bearbeitungsprozess
                    <br />
                    Die Liste wurde in der Zwischenzeit aktualisiert.
                </p>
            ),
            type: "info",
        } as any)
    } else {
        addNotification(genericError(error))
    }
}

export function handleAnfragelisteMutation<T>(
    { data, success, error }: Response<T>,
    successNotification: ((data: T) => NotificationData) | NotificationData,
    addNotification: NotificationsContext["addNotification"],
    action?: string
) {
    if (success && data) {
        addNotification(typeof successNotification === "function" ? successNotification(data) : successNotification)
    } else if (error) {
        handleAnfragelisteAktionError(error, addNotification)
    } else {
        genericError({ action, data, success, error })
    }
}
