import { ApiProvider } from "@isbj-redesign/frontend-api"
import React, { ReactNode } from "react"
import unfetch from "unfetch"
import { useAuth, AuthProvider } from "./auth"

export default function AuthenticatedApiProvider({ children }: { children: ReactNode }) {
    return (
        <AuthProvider>
            <InnerProvider>{children}</InnerProvider>
        </AuthProvider>
    )
}

function InnerProvider({ children }: { children: ReactNode }) {
    const { user } = useAuth()
    return (
        <ApiProvider token={user.token} fetch={unfetch}>
            {children}
        </ApiProvider>
    )
}
