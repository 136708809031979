import { AnfrageRestModel } from "../AnfrageRestModel"
import { AnfrageAdresse, AnfrageBetreuungsperson, AnfrageBetreuungsumfang, AnfrageKind, Anfrage, AnfrageRbsInformationen } from "../../../core/model/Anfrage"

type GetDefined<T, K extends keyof T> = Exclude<T[K], undefined>

type AnfrageRestBetreuungsperson = GetDefined<AnfrageRestModel, "betreuungsperson">
type AnfrageRestKind = GetDefined<AnfrageRestModel, "kind">

export function anfrageRestConverter(anfrage?: AnfrageRestModel): Anfrage {
    if (!anfrage) {
        return {} as Anfrage
    }

    const {
        anfragedatum,
        anfragenummer,
        betreuungsperson,
        betreuungsumfang,
        einrichtung,
        id,
        kind,
        letzteAenderung,
        letzterBearbeiter,
        status,
        validierungsstatus,
    } = anfrage

    return {
        id,
        anfragedatum,
        anfragenummer,
        betreuungsperson: betreuungspersonFormConverter(betreuungsperson),
        betreuungsumfang: betreuungsumfangFormConverter(betreuungsumfang),
        einrichtung: {
            name: einrichtung ? einrichtung.name : "",
            nummer: einrichtung ? einrichtung.nummer : "",
        },
        kind: kindFormConverter(kind, betreuungsperson),
        validierungsstatus: validierungsstatus || [],
        letzteAenderung,
        status,
        letzterBearbeiter: letzterBearbeiter || "letzterBearbeiter",
    }
}

function entfernungFormConverter(entfernung: number | null): string {
    if (!entfernung) {
        return ""
    }

    if (entfernung !== null && entfernung !== undefined) {
        return `${parseFloat(entfernung.toString()).toFixed(2)} km`
    }

    return ""
}

function kindFormConverter(kind?: AnfrageRestKind, betreuungsperson?: AnfrageRestBetreuungsperson): AnfrageKind {
    if (!kind) {
        return {
            einrichtungsEntfernung: "",
            geboren: false,
            geburtsdatum: "",
            geschlecht: "",
            gleicheanschrift: false,
            nachname: "",
            vorname: "",
            mehrling: false,
            adresse: {
                hausnummer: "",
                ort: "",
                plz: "",
                strasse: "",
                bezirk: "",
                ortsteil: "",
                planungsraum: "",
                rbsStatus: "",
            },
        }
    }

    const { adresse, einrichtungsEntfernung, geboren, geburtsdatum, geschlecht, nachname, vorname, mehrling } = kind

    let gleicheanschrift = false
    if (betreuungsperson && betreuungsperson.adresse && adresse) {
        const { adresse: adresseB } = betreuungsperson
        gleicheanschrift =
            adresse.hausnummer === adresseB.hausnummer && adresse.strasse === adresseB.strasse && adresse.ort === adresseB.ort && adresse.plz === adresseB.plz
    }

    return {
        einrichtungsEntfernung: entfernungFormConverter(einrichtungsEntfernung),
        geboren: geboren === true,
        geburtsdatum: geburtsdatum || "",
        geschlecht: geschlecht || "",
        gleicheanschrift,
        nachname: nachname || "",
        vorname: vorname || "",
        mehrling: mehrling === true,
        adresse: {
            ...adresseFormConverter(adresse),
            ...rbsInformationenFormConverter(adresse),
        },
    }
}

function betreuungsumfangFormConverter(betreuungsumfang?: AnfrageRestModel["betreuungsumfang"]): AnfrageBetreuungsumfang {
    if (!betreuungsumfang) {
        return {
            betreuungsbeginn: "",
            betreuungszeit: "",
        }
    }

    const { betreuungsbeginn, betreuungszeit } = betreuungsumfang

    return {
        betreuungsbeginn,
        betreuungszeit,
    }
}

function betreuungspersonFormConverter(betreuungsperson?: AnfrageRestBetreuungsperson): AnfrageBetreuungsperson {
    if (!betreuungsperson) {
        return {
            email: "",
            kontakt: null,
            nachname: "",
            telefon: "",
            vorname: "",
            adresse: {
                hausnummer: "",
                ort: "",
                plz: "",
                strasse: "",
            },
        }
    }

    const { adresse, email, nachname, telefon, vorname, kontakt } = betreuungsperson

    return {
        adresse: adresseFormConverter(adresse),
        email: email || "",
        nachname: nachname || "",
        telefon: telefon || "",
        vorname: vorname || "",
        kontakt: kontakt || null,
    }
}

function adresseFormConverter(adresse?: AnfrageRestBetreuungsperson["adresse"]): AnfrageAdresse {
    if (!adresse) {
        return {
            hausnummer: "",
            ort: "",
            plz: "",
            strasse: "",
        }
    }

    const { hausnummer, ort, plz, strasse } = adresse

    return {
        hausnummer: hausnummer || "",
        ort: ort || "",
        plz: plz || "",
        strasse: strasse || "",
    }
}

function rbsInformationenFormConverter(adresse?: AnfrageRestKind["adresse"]): AnfrageRbsInformationen {
    if (!adresse) {
        return {
            bezirk: "",
            ortsteil: "",
            planungsraum: "",
            rbsStatus: "",
        }
    }

    const { bezirk, ortsteil, planungsraum, rbsStatus } = adresse

    return {
        bezirk: bezirk || "",
        ortsteil: ortsteil || "",
        planungsraum: planungsraum || "",
        rbsStatus: rbsStatus || "",
    }
}
