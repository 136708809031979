import React from "react"
import { Row, Col, Display, Justify, Button, ButtonType } from "@isbj-redesign/frontend-components"
import { Link } from "react-router-dom"

export interface Props {
    abbrechenHref: string
}

export default function AnfrageFormFooter({ abbrechenHref }: Props) {
    return (
        <Row>
            <Col marginY={3}>
                <Link className="btn btn-outline-secondary" to={abbrechenHref}>
                    Zurück zur Liste
                </Link>
            </Col>
            <Col display={Display.Flex} justifyContent={Justify.End} marginY={3}>
                <Button type={ButtonType.Submit}>Speichern</Button>
            </Col>
        </Row>
    )
}
