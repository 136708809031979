import { bearbeitungsValidierungsSchema } from "../../bearbeitung/validierung/BearbeitungValidierung"
import { AnfrageBearbeitungFormFields } from "../../bearbeitung/AnfrageBearbeitungFormFields"
import { getEinrichtung } from "../../../services/Einrichtung"
import { ValidationError } from "yup"

const erstellenValidierungsSchema = bearbeitungsValidierungsSchema.test(AnfrageBearbeitungFormFields.Betreuungsumfang.Einrichtung, "", async obj => {
    const value: string = obj[AnfrageBearbeitungFormFields.Betreuungsumfang.Einrichtung]

    if (value === undefined || value === "" || value === null) {
        return true
    }

    const einrichtungen = await getEinrichtung()
    const einrichtungenValues = einrichtungen.map(v => ({ label: `${v.nummer} ${v.name}`, value: v.nummer }))

    if (einrichtungenValues.findIndex(einrichtung => einrichtung.value === value) > -1) {
        return true
    }

    return new ValidationError("Bitte wählen Sie eine Einrichtung aus.", "", AnfrageBearbeitungFormFields.Betreuungsumfang.Einrichtung)
})

export default erstellenValidierungsSchema
