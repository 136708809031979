import decodeJWT from "jwt-decode"
import React, { ReactNode } from "react"
import { User } from "./api"
import { AuthContext, Context } from "./context"
import { cutParam } from "../../utils/utils"
import { Color, Alert } from "@isbj-redesign/frontend-components"

export interface Props {
    children: ReactNode
}

export function getLogoutUrl() {
    return window.isbj ? window.isbj.env.dashboardUrl + "/logout" : ""
}

export function AuthAlert({ children }: { children: ReactNode }) {
    return (
        <Alert color={Color.Warning}>
            <h3>{children}</h3>
            <hr />
            <p className="mb-0">
                Bitte versuchen Sie sich{" "}
                <a className="bold" href={getLogoutUrl()}>
                    neu anzumelden
                </a>{" "}
                und die Aktion erneut durchzuführen.
            </p>
        </Alert>
    )
}

export function AuthProvider({ children }: Props) {
    const token = cutParam("jwt", false)

    if (!token) {
        return <AuthAlert>Es wurde kein Logintoken übergeben</AuthAlert>
    }

    try {
        const value: AuthContext = {
            user: decodeUser(token),
        }
        return <Context.Provider value={value}>{children}</Context.Provider>
    } catch (e) {
        return <AuthAlert>Fehler beim Auslesen der Benutzerinformationen</AuthAlert>
    }
}

export function decodeUser(jwt: string): User {
    const data: any = decodeJWT(jwt)

    return {
        token: jwt,
        benutzername: data.sub,
        tokenAusgestelltAm: new Date(data.iat * 1000),
        tokenGueltigBis: new Date(data.exp * 1000),
        gruppen: data.gruppen || [],
    }
}
