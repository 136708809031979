import { FieldInput } from "@isbj-redesign/final-form-components"
import React from "react"

export interface Props {
    name: string
    required: boolean
    disabled?: boolean
}

export default function FieldPlzInput(props: Props) {
    return (
        <FieldInput
            label="PLZ"
            {...props}
            maxLength={5}
            restrictions={/\d/g}
            // TODO https://www.deque.com/blog/accessible-client-side-form-validation-html5/
        />
    )
}
