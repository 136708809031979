import { FieldAutoSuggest } from "@isbj-redesign/final-form-components"
import React, { useEffect, useState } from "react"
import { useAuth } from "../providers/auth"
import { getEinrichtung } from "../services/Einrichtung"

export interface Props {
    name: string
    disabled?: boolean
    required?: boolean
}

export default function EinrichtungInput(props: Props) {
    const [einrichtungen, setEinrichtungen] = useState<Array<{ value: string; label: string }>>([])
    const { user } = useAuth()

    useEffect(() => {
        getEinrichtung().then(values => {
            setEinrichtungen(values.map(v => ({ label: `${v.nummer} ${v.name}`, value: v.nummer })))
        })
    }, [user.token])

    return <FieldAutoSuggest label="Einrichtung" options={einrichtungen} maxLength={255} filter highlightSuggestionLabel {...props} />
}
