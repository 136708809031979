import { ParameterUtils } from "@nortal/commons-frontend"
import { DateUtils } from "@isbj-redesign/frontend-components"
import React from "react"
import { Anfrage } from "../../core/model/Anfrage"
import { transformEnumString } from "../../utils/utils"
import RBSInformationenGrid from "./components/RBSInformationen"

export interface Props {
    data: Anfrage
}

export default function AnfrageInformationen({ data }: Props) {
    return (
        <>
            <h4>Informationen zur Anfrage</h4>
            <div className="list-group-item">
                <div className="d-flex w-100 justify-content-between">
                    <dt>Anfrage-ID</dt>
                    <dd className="mb-0">{data.anfragenummer}</dd>
                </div>
                <hr className="my-2" />
                <div className="d-flex w-100 justify-content-between">
                    <dt>Datum der Anfrage</dt>
                    <dd className="mb-0">{data.anfragedatum ? DateUtils.convertDateToString(ParameterUtils.parseDate(data.anfragedatum)!) : "unbekannt"}</dd>
                </div>
                <hr className="my-2" />
                <div className="d-flex w-100 justify-content-between">
                    <dt>Ungeborene Mehrlinge</dt>
                    <dd className="mb-0">{!data.kind.geboren && data.kind.mehrling ? "Ja" : "Nein"}</dd>
                </div>
                <hr className="my-2" />
                <div className="d-flex w-100 justify-content-between">
                    <dt>Einrichtung</dt>
                    <dd className="mb-0">
                        {data.einrichtung.nummer} {data.einrichtung.name}
                    </dd>
                </div>
                <hr className="my-2" />
                <div className="d-flex w-100 justify-content-between">
                    <dt>Letzte Änderung</dt>
                    <dd className="mb-0">{data.letzteAenderung ? DateUtils.convertDateToString(ParameterUtils.parseDate(data.letzteAenderung)!) : ""}</dd>
                </div>
                <hr className="my-2" />
                <div className="d-flex w-100 justify-content-between">
                    <dt>Letzter Bearbeiter</dt>
                    <dd className="mb-0">{data.letzterBearbeiter}</dd>
                </div>
            </div>
            <h4 className="mt-3">Statusinformationen</h4>
            <div className="list-group-item">
                <div className="d-flex w-100 justify-content-between">
                    <dt>Status</dt>
                    <dd className="mb-0">{transformEnumString(data.status)}</dd>
                </div>
            </div>
            <h4 className="mt-3">RBS Informationen</h4>
            <div className="list-group-item">
                {data.kind.einrichtungsEntfernung && (
                    <>
                        <div className="d-flex w-100 justify-content-between">
                            <dt>Entfernung</dt>
                            <dd className="mb-0">{data.kind.einrichtungsEntfernung}</dd>
                        </div>
                        <hr className="my-2" />
                    </>
                )}
                {data.kind.adresse && <RBSInformationenGrid adresse={data.kind.adresse} />}
            </div>
        </>
    )
}
