import { DateUtils } from "@isbj-redesign/frontend-components"
import { object, string, StringSchema } from "yup"
import { ValidationMessage } from "../../../core/validation/Messages"
import { isEmptyString } from "../../../core/validation/StringValidator"
import { Anfrage } from "../../../core/model/Anfrage"

export const kindValidierungShape = object().shape({
    nachname: string().required(ValidationMessage.Required),
    vorname: string().when("geboren", {
        is: true,
        then: string().required(ValidationMessage.Required),
        otherwise: string().notRequired(),
    }),
    geschlecht: string().when("geboren", {
        is: true,
        then: string().required(ValidationMessage.Required),
        otherwise: string().notRequired(),
    }),
    geburtsdatum: string()
        .when("geboren", {
            is: true,
            then: string().required(ValidationMessage.Required),
            otherwise: string().notRequired(),
        })
        .test("dateValidation", ValidationMessage.InvalidDate, value => {
            if (isEmptyString(value)) {
                return true
            }

            const split = value.split("-")

            if (split.length === 3) {
                if ((DateUtils as any).isValidDateStringArray(split)) {
                    return true
                }
            }

            return false
        }),
    adresse: object().shape({
        strasse: string().when("$data", (data: Anfrage, schema: StringSchema) => {
            return !data.kind.gleicheanschrift ? schema.required(ValidationMessage.Required) : schema
        }),
        hausnummer: string().when("$data", (data: Anfrage, schema: StringSchema) => {
            return !data.kind.gleicheanschrift ? schema.required(ValidationMessage.Required) : schema
        }),
        plz: string()
            .when("$data", (data: Anfrage, schema: StringSchema) => {
                return !data.kind.gleicheanschrift ? schema.min(5, ValidationMessage.ExactLengthFive) : schema
            })
            .when("$data", (data: Anfrage, schema: StringSchema) => {
                return !data.kind.gleicheanschrift ? schema.required(ValidationMessage.Required) : schema
            }),
        ort: string().when("$data", (data: Anfrage, schema: StringSchema) => {
            return !data.kind.gleicheanschrift ? schema.required(ValidationMessage.Required) : schema
        }),
    }),
})
