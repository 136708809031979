import { Einrichtung } from "../core/model/Einrichtung"
import { cutParam } from "../utils/utils"

let einrichtungen: Array<Einrichtung> | null = null

export async function getEinrichtung(): Promise<Array<Einrichtung>> {
    if (einrichtungen !== null) {
        return einrichtungen
    }

    const jwt = cutParam("jwt", false)

    if (jwt) {
        try {
            const res = await fetch("/api/v1/einrichtungen", {
                headers: {
                    Authorization: "Bearer " + jwt,
                },
            })
            einrichtungen = (await res.json()).eintraege
            return einrichtungen!
        } catch (e) {
            console.error("Fehler beim auslesen der Einrichtungen.", e)
            return []
        }
    } else {
        return []
    }
}
