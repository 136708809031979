import { Tab, Tabs, Row, Col } from "@isbj-redesign/frontend-components"
import FaFileAltLight from "@isbj-redesign/icons/FaFileAltLight"
import React, { useState } from "react"
import { Redirect, RouteChildrenProps } from "react-router"
import Layout from "../../../components/Layout"
import { getLogoutUrl } from "../../../providers/auth"
import { getOrigin } from "../../../services/getOrigin"
import { VormerkungAnfrageLoader } from "./components/VormerkungAnfrageLoader"
import KindAnzeigen from "./forms/KindAnzeigen"
import BetreuungsumfangAnzeigen from "./forms/BetreuungsumfangAnzeigen"
import BetreuungspersonAnzeigen from "./forms/BetreuungspersonAnzeigen"
import KontaktInformationen from "../../bearbeitung/components/KontaktInformationen"
import AnfrageInformationen from "../../bearbeitung/AnfrageInformationen"

export default function VormerkungenAnzeigen({ match }: RouteChildrenProps<{ id: string }>) {
    const [tab, setTab] = useState(0)

    let id = ""
    if (!match || !match.params || !match.params.id) {
        return <Redirect to={"/vormerkungen/not-found"} />
    } else {
        id = match.params.id
    }

    const origin = getOrigin() || getLogoutUrl()

    return (
        <Layout title="Anfrage zur Vormerkung anzeigen" protectedPage>
            <VormerkungAnfrageLoader id={id}>
                {data => {
                    return (
                        <Tabs activeIndex={tab} onClick={index => setTab(index)} className="bg-white row pt-3 mb-3 px-3">
                            <Tab
                                title={
                                    <h3 className="m-0">
                                        <FaFileAltLight className="mr-2" style={{ fontSize: "1rem" }} />
                                        Angaben zur Anfrage
                                    </h3>
                                }
                            >
                                <Row>
                                    <Col xs={12} lg={6} xl={8} order={{ xs: 2, lg: 1 }}>
                                        <KindAnzeigen kind={data.kind} />
                                        <BetreuungsumfangAnzeigen betreuungsumfang={data.betreuungsumfang} />

                                        <BetreuungspersonAnzeigen betreuungsperson={data.betreuungsperson} />
                                        <KontaktInformationen kontakt={data.betreuungsperson.kontakt} />
                                    </Col>
                                    <Col lg={6} xl={4} order={{ xs: 1, lg: 2 }} marginBottom={3}>
                                        <AnfrageInformationen data={data} />
                                    </Col>
                                </Row>
                                <a className="btn btn-outline-secondary mb-3" href={origin}>
                                    Zurück zum Trägerportal
                                </a>
                            </Tab>
                        </Tabs>
                    )
                }}
            </VormerkungAnfrageLoader>
        </Layout>
    )
}
