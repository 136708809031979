import { Row, Col, Input } from "@isbj-redesign/frontend-components"
import React from "react"
import { AnfrageBetreuungsperson } from "../../../../core/model/Anfrage"

export interface Props {
    betreuungsperson: AnfrageBetreuungsperson
}

export default function BetreuungspersonAnzeigen({ betreuungsperson }: Props) {
    return (
        <>
            <h4 className="mt-3">Angaben zur Betreuungsperson</h4>
            <hr />
            <Row>
                <Col xs={12} sm={6}>
                    <Input id="betreuungsperson-nachname" value={betreuungsperson.nachname} label="Nachname" readOnly plaintext />
                </Col>
                <Col xs={12} sm={6}>
                    <Input value={betreuungsperson.vorname} label="Vorname" readOnly plaintext />
                </Col>
                <Col xs={12} sm={8} lg={9} xl={9}>
                    <Input value={betreuungsperson.adresse.strasse} label="Straße" readOnly plaintext />
                </Col>
                <Col xs={12} sm={4} lg={3} xl={3}>
                    <Input value={betreuungsperson.adresse.hausnummer} label="Hausnummer" readOnly plaintext />
                </Col>
                <Col xs={12} sm={4} lg={3} xl={3}>
                    <Input value={betreuungsperson.adresse.plz} label="PLZ" readOnly plaintext />
                </Col>
                <Col xs={12} sm={8} lg={9} xl={9}>
                    <Input value={betreuungsperson.adresse.ort} label="Ort" readOnly plaintext />
                </Col>
                <Col xs={12} sm={6}>
                    <Input value={betreuungsperson.telefon} label="Telefon" readOnly plaintext />
                </Col>
                <Col xs={12} sm={6}>
                    <Input value={betreuungsperson.email} label="E-Mail" readOnly plaintext />
                </Col>
            </Row>
        </>
    )
}
