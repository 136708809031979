import { Col, Row, Input, Checkbox, DateUtils } from "@isbj-redesign/frontend-components"
import React from "react"
import { AnfrageKind } from "../../../../core/model/Anfrage"
import { Geschlecht } from "../../../../core/model/Geschlecht"
import { ParameterUtils } from "@nortal/commons-frontend"

export interface Props {
    kind: AnfrageKind
}

const Geschlechter = {
    "": "",
    [Geschlecht.Divers]: "Divers",
    [Geschlecht.Weiblich]: "Weiblich",
    [Geschlecht.Maennlich]: "Männlich",
    [Geschlecht.OhneAngabe]: "Keine Angabe gem. § 45b Personenstandsgesetz",
}

export default function KindAnzeigen({ kind }: Props) {
    return (
        <>
            <h4>Angaben zum Kind</h4>
            <hr />
            <Row>
                <Col xs={12} sm={6}>
                    <Input id="kind-nachname" label="Nachname" readOnly plaintext value={kind.nachname} />
                </Col>
                <Col xs={12} sm={6}>
                    <Input id="kind-vorname" label="Vorname" readOnly plaintext value={kind.vorname} />
                </Col>

                <Col xs={12} marginBottom={1}>
                    <Checkbox id="kind-ist" label="Ist das Kind bereits geboren?" readOnly disabled checked={kind.geboren} />
                </Col>
                {kind.geboren && (
                    <>
                        <Col xs={12} xl={6}>
                            <Input
                                id="kind-geburtsdatum"
                                label="Geburtsdatum"
                                readOnly
                                plaintext
                                value={kind.geburtsdatum ? DateUtils.convertDateToString(ParameterUtils.parseDate(kind.geburtsdatum)!) : ""}
                            />
                        </Col>
                        <Col xs={12} xl={6}>
                            <Input id="kind-geschlecht" label="Geschlecht" readOnly plaintext value={Geschlechter[kind.geschlecht]} />
                        </Col>
                    </>
                )}

                <Col xs={12} sm={6} marginBottom={1}>
                    <Checkbox id="kind-gleiche" label="Gleiche Anschrift wie Betreuungsperson?" readOnly disabled checked={kind.gleicheanschrift} />
                </Col>
                {!kind.gleicheanschrift && (
                    <>
                        <Col xs={12} sm={8} lg={9} xl={9}>
                            <Input id="kind-strasse" label="Straße" readOnly plaintext value={kind.adresse.strasse} />
                        </Col>
                        <Col xs={12} sm={4} lg={3} xl={3}>
                            <Input id="kind-hausnummer" label="Hausnummer" readOnly plaintext value={kind.adresse.hausnummer} />
                        </Col>
                        <Col xs={12} sm={4} lg={3} xl={3}>
                            <Input id="kind-plz" label="PLZ" readOnly plaintext value={kind.adresse.plz} />
                        </Col>
                        <Col xs={12} sm={8} lg={9} xl={9}>
                            <Input id="kind-ort" label="Ort" readOnly plaintext value={kind.adresse.ort} />
                        </Col>
                    </>
                )}
            </Row>
        </>
    )
}
