import React, { ReactNode } from "react"
import { Row, Col, Alert, Color } from "@isbj-redesign/frontend-components"
import FaExclamationTriangleRegular from "@isbj-redesign/icons/FaExclamationTriangleRegular"

export interface Props {
    children: ReactNode
}

export default function ErrorAlert({ children }: Props) {
    return (
        <Row>
            <Col xs={12} sm={12}>
                <Alert color={Color.Danger}>
                    <h3 className="alert-heading h2">
                        <FaExclamationTriangleRegular className="mr-1" />
                        Fehler
                    </h3>
                    <hr />
                    <p className="m-0">{children}</p>
                </Alert>
            </Col>
        </Row>
    )
}
