import CheckIcon from "@isbj-redesign/icons/FaCheckCircleLight"
import ExclamationIcon from "@isbj-redesign/icons/FaExclamationTriangleLight"
import InfoIcon from "@isbj-redesign/icons/FaInfoCircleLight"
import DismissIcon from "@isbj-redesign/icons/FaTimesLight"
import IconProps from "@isbj-redesign/icons/props"

import React, { ComponentType, useState, useEffect } from "react"
import { NotificationData, NotificationType, withNotifications } from "@isbj-redesign/shared-components"

const icons: { [key in NotificationType]?: ComponentType<IconProps> } = {
    info: InfoIcon,
    success: CheckIcon,
    warning: ExclamationIcon,
    error: ExclamationIcon,
}

const bootstrapClasses: { [key in NotificationType]: string } = {
    info: "info",
    success: "success",
    warning: "warning",
    error: "danger",
}

export interface NotificationDetailsProps {
    notification: NotificationData
    dismiss(): void
}

export const Notifications = withNotifications(({ notifications, dismissNotification }) => {
    if (notifications.length === 0) {
        return null
    }

    return (
        <div className="list-unstyled position-fixed fixed-bottom ml-auto px-2 notifications-container" style={{ width: "fit-content" }}>
            {notifications.map(n => (
                <CustomNotification key={n.id} notification={n} dismiss={() => dismissNotification(n.id)} />
            ))}
        </div>
    )
})

export function CustomNotification({ notification, dismiss }: NotificationDetailsProps) {
    const Icon = icons[notification.type]
    const bsClass = bootstrapClasses[notification.type]

    const [animation, setAnimation] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setAnimation(false)
        }, 10)
    }, [])

    return (
        <div className={`notification${animation ? " neu" : ""}`}>
            <div role="alert" className={`alert alert-${bsClass} mb-2`} style={{ width: "100%", marginLeft: "auto" }}>
                <h3>
                    {Icon && <Icon className="mr-2" />}
                    {(notification as any).title}
                    <button
                        className="close ml-2"
                        onClick={() => {
                            setAnimation(true)
                            setTimeout(() => dismiss(), 200)
                        }}
                        title="Benachrichtigung ausblenden"
                    >
                        <DismissIcon aria-hidden="true" />
                    </button>
                </h3>
                <hr className="mt-0" />
                {notification.message}
            </div>
        </div>
    )
}
